import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import axios from "axios";

interface AddressFormProps {
  formValues: Partial<{
    cep: string;
    state: string;
    city: string;
    neighborhood: string;
    street: string;
    residence_number: string;
    complement: string;
  }>;
  setFormValues: React.Dispatch<React.SetStateAction<any>>;
}

const AddressForm: React.FC<AddressFormProps> = ({
  formValues,
  setFormValues,
}) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleSearchCep = async () => {
    if (!formValues.cep) {
      setErrorMessage("Por favor, digite um CEP válido.");
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `https://brasilapi.com.br/api/cep/v1/${formValues.cep}`
      );
      const { state, city, neighborhood, street } = response.data;

      setFormValues((prevValues: any) => ({
        ...prevValues,
        state,
        city,
        neighborhood,
        street,
      }));

      setErrorMessage(null);
    } catch (error) {
      setErrorMessage("CEP não encontrado.");
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNumberInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const onlyNumbers = value.replace(/\D/g, "");
    setFormValues({
      ...formValues,
      [name]: onlyNumbers,
    });
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearchCep();
    }
  };

  return (
    <div>
      <div className="flex flex-wrap -mx-2">
        {/* Primeira Coluna */}
        <div className="w-full md:w-1/2 px-2 mb-4">
          <label className="block mb-2">
            <span className="text-red-500">*</span>CEP:
            <div className="relative">
              <MagnifyingGlassIcon
                className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={handleSearchCep}
              />
              <input
                type="text"
                name="cep"
                value={formValues.cep || ""}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
                className="w-full p-2 pl-10 border border-gray-300 rounded mt-1"
                placeholder="Digite o CEP"
                required
              />
            </div>
            {errorMessage && <div className="text-red-500">{errorMessage}</div>}
          </label>

          <label className="block mb-2">
            <span className="text-red-500">*</span>Estado:
            <input
              type="text"
              name="state"
              value={formValues.state || ""}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
              placeholder="Digite seu estado"
              required
            />
          </label>

          <label className="block mb-2">
            <span className="text-red-500">*</span>Cidade:
            <input
              type="text"
              name="city"
              value={formValues.city || ""}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
              placeholder="Digite a sua cidade"
              required
            />
          </label>
        </div>

        {/* Segunda Coluna */}
        <div className="w-full md:w-1/2 px-2 mb-4">
          <label className="block mb-2">
            <span className="text-red-500">*</span>Bairro:
            <input
              type="text"
              name="neighborhood"
              value={formValues.neighborhood || ""}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
              placeholder="Digite o seu bairro"
              required
            />
          </label>

          <label className="block mb-2">
            <span className="text-red-500">*</span>Rua:
            <input
              type="text"
              name="street"
              value={formValues.street || ""}
              onChange={handleInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
              placeholder="Digite sua rua"
              required
            />
          </label>

          <label className="block mb-2">
            <span className="text-red-500">*</span>Número Residência:
            <input
              type="text"
              name="residence_number"
              value={formValues.residence_number || ""}
              onChange={handleNumberInputChange}
              className="w-full p-2 border border-gray-300 rounded mt-1"
              placeholder="Somente números"
              required
            />
          </label>
        </div>
      </div>
      <label className="block mb-2">
        Complemento (Opcional):
        <input
          type="text"
          name="complement"
          value={formValues.complement || ""}
          onChange={handleInputChange}
          className="w-full p-2 border border-gray-300 rounded mt-1"
          placeholder="Ex: Bloco B, Apto 302"
        />
      </label>

      {loading && <div className="text-gray-500">Buscando dados do CEP...</div>}
    </div>
  );
};

export default AddressForm;
