import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import authService from "../services/auth-Service";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

interface Credentials {
  email: string;
  password: string;
}

interface PasswordReset {
  document: string;
  newPassword: string;
  confirmPassword: string;
}

const Login = () => {
  const navigate = useNavigate();

  const [credentials, setCredentials] = useState<Credentials>({
    email: "",
    password: "",
  });

  const [passwordReset, setPasswordReset] = useState<PasswordReset>({
    document: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [isResetPasswordMode, setIsResetPasswordMode] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  useEffect(() => {
    authService.logout();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    isPasswordReset: boolean = false
  ) => {
    const { name, value } = e.target;
    if (isPasswordReset) {
      setPasswordReset({
        ...passwordReset,
        [name]: value,
      });
    } else {
      setCredentials({
        ...credentials,
        [name]: value,
      });
    }
  };

  const handleDocumentInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const numericValue = value.replace(/\D/g, '');
    setPasswordReset({
      ...passwordReset,
      [name]: numericValue,
    });
  };

  const handleRegistry = () => {
    navigate("/registry");
  };
  const handleLandingPage = () => {
    navigate("/");
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isResetPasswordMode) {
      if (passwordReset.newPassword !== passwordReset.confirmPassword) {
        toast.error("As senhas não coincidem.");
        return;
      }

      try {
        const response = await authService.resetPassword(
          passwordReset.document,
          passwordReset.newPassword
        );

        toast.success(response.detail);
        setIsResetPasswordMode(false);
        
      } catch (error: any) {
        const errorMessage = error.message;
        toast.error(errorMessage);
      }
    } else {
      try {
        await authService.login(credentials.email, credentials.password);
        const userInfo = authService.getUserInfo();
        if (userInfo) {
          if (userInfo.is_staff) {
            navigate("/sales-list");
          } else {
            navigate(`/professional-points/${userInfo.id}`);
          }
        } else {
          toast.error("Falha ao obter informações do usuário.");
        }
      } catch (error: any) {
        toast.error(error.message || "Login falhou. Verifique suas credenciais.");
      }
    }
  };

  const handleForgotPasswordClick = () => {
    setIsResetPasswordMode(true);
  };

  const handleBackToLoginClick = () => {
    setIsResetPasswordMode(false);
  };

  return (
    <div
      className="min-h-screen flex items-center justify-center bg-gray-100"
      style={{
        backgroundImage: "url('/images/login-background.png')",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
      }}
    >
      <div className="bg-white md:p-0 rounded shadow-lg w-full max-w-xs sm:max-w-md md:max-w-lg lg:max-w-4xl min-h-[400px] flex flex-col md:flex-col lg:grid lg:grid-cols-2 gap-8">
        <div
          className="bg-cover bg-center rounded-t-lg md:rounded-lg lg:rounded-r-lg h-64 md:h-80 lg:h-auto lg:order-last cursor-pointer"
          onClick={handleLandingPage}
          style={{
            backgroundImage: "url('/images/login-clubgvs.png')",
          }}
        ></div>

        <div className="p-4 md:p-8">
          <h1 className="text-4xl pb-4 font-adam font-bold text-left">
            {isResetPasswordMode ? "Redefinir senha" : "Login"}
          </h1>
          {error && <p className="text-red-500 text-center mb-4">{error}</p>}
          <form onSubmit={handleSubmit} className="space-y-4">
            {isResetPasswordMode ? (
              <>
                <div>
                  <label className="block text-sm font-medium mb-2">
                    CPF/CNPJ:
                  </label>
                  <input
                    type="text"
                    name="document"
                    value={passwordReset.document}
                    onChange={(e) => handleDocumentInput(e)}
                    required
                    placeholder="Somente números"
                    className="input-primary w-full"
                  />
                </div>
                <div className="relative">
                  <label className="block text-sm font-medium mb-2">
                    Nova Senha:
                  </label>
                  <input
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    value={passwordReset.newPassword}
                    onChange={(e) => handleInputChange(e, true)}
                    required
                    className="input-primary w-full"
                    placeholder="Nova senha"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    {showNewPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </div>
                </div>
                <div className="relative">
                  <label className="block text-sm font-medium mb-2">
                    Confirme a Nova Senha:
                  </label>
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={passwordReset.confirmPassword}
                    onChange={(e) => handleInputChange(e, true)}
                    required
                    className="input-primary w-full"
                    placeholder="Confirme a Nova Senha"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    {showConfirmPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </div>
                </div>
                <button type="submit" className="btn-primary w-full">
                  Redefinir Senha
                </button>
                <button
                  className="text-primary-light hover:underline focus:outline-none mt-4"
                  type="button"
                  onClick={handleBackToLoginClick}
                >
                  Voltar
                </button>
              </>
            ) : (
              <>
                <div>
                  <label className="block text-sm font-medium mb-2">
                    E-mail:
                  </label>
                  <input
                    type="text"
                    name="email"
                    value={credentials.email}
                    onChange={handleInputChange}
                    required
                    className="input-primary w-full"
                  />
                </div>
                <div className="relative">
                  <label className="block text-sm font-medium mb-2">
                    Senha:
                  </label>
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={credentials.password}
                    onChange={handleInputChange}
                    required
                    className="input-primary w-full"
                  />
                  <div
                    className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? (
                      <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <EyeIcon className="h-5 w-5 text-gray-500" />
                    )}
                  </div>
                </div>
                <button type="submit" className="btn-primary w-full">
                  Entrar
                </button>
              </>
            )}
          </form>

          {!isResetPasswordMode && (
            <div className="flex justify-between items-center mt-8 text-light">
              <button
                className="text-primary-light hover:underline focus:outline-none"
                onClick={handleForgotPasswordClick}
              >
                Esqueceu a senha?
              </button>

              <div className="text-right">
                <span className="text-primary-light">Não tem uma conta? </span>
                <button
                  className="text-primary hover:underline focus:outline-none"
                  onClick={handleRegistry}
                >
                  Cadastre-se
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
