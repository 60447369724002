import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { registerProfessional } from "../services/professional-service";
import Header from "./header";
import Footer from "./footer";
import {
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import Checkbox from "./ui-components/checkbox";
import { validateDocument } from "../utils/document-validator";
import { toast } from "react-toastify";
import AddressForm from "./address-form";
import ImageUploader from "./ui-components/image-uploader";

interface FormData {
  email: string;
  document: string;
  phone: string;
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  residence_number: string;
  street: string;
  password: string;
  name: string;
  is_active: boolean;
  professional_type: string;
  professional_register: string;
  birthdate: string;
}

const Registry = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState<Partial<FormData>>({
    email: "",
    document: "",
    phone: "",
    cep: "",
    state: "",
    city: "",
    neighborhood: "",
    residence_number: "",
    street: "",
    password: "",
    name: "",
    is_active: false,
    professional_type: "",
    professional_register: "",
    birthdate: "",
  });

  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [imagePreview, setImagePreview] = useState<string | null>(null);
  const [termsErrorMessage, setTermsErrorMessage] = useState<string | null>(
    null
  );
  const [imageErrorMessage, setImageErrorMessage] = useState<string | null>(
    null
  );
  const [isChecked, setIsChecked] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<string | null>(null);
  
  const MAX_FILE_SIZE_MB = 1;

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleCheckboxChange = (e: any) => {
    const { name, checked } = e.target;
    setIsChecked(!isChecked);
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleProfessionalTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      professional_type: value,
    });
  };

  const handleInputChangeDocument = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = e.target;

    if (name === "document") {
      const cleanedValue = value.replace(/\D/g, "");
      setFormData({
        ...formData,
        [name]: cleanedValue,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value;
    phone = phone.replace(/\D/g, "");
    if (phone.length > 0) {
      phone = phone.replace(/^(\d{2})(\d)/g, "($1)$2");
    }
    if (phone.length >= 9) {
      phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
    }

    setFormData({
      ...formData,
      phone,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formData.password !== confirmPassword) {
      setPasswordErrorMessage("As senhas não são iguais.");
      return;
    }
    setPasswordErrorMessage(null);

    if (!isChecked) {
      setTermsErrorMessage("Você precisa aceitar os termos");
      return;
    }

    const DocumentError = validateDocument(formData.document || "");
    if (DocumentError) {
      setTermsErrorMessage(DocumentError);
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("email", formData.email || "");
    formDataToSend.append("document", formData.document || "");
    formDataToSend.append("phone", formData.phone || "");
    formDataToSend.append("password", formData.password || "");
    formDataToSend.append("birthdate", formData.birthdate || "");
    formDataToSend.append("cep", formData.cep || "");
    formDataToSend.append("state", formData.state || "");
    formDataToSend.append("city", formData.city || "");
    formDataToSend.append("neighborhood", formData.neighborhood || "");
    formDataToSend.append("professional_register", formData.professional_register || "");
    formDataToSend.append("residence_number", formData.residence_number || "");
    formDataToSend.append("street", formData.street || "");
    formDataToSend.append("name", formData.name || "");
    formDataToSend.append("is_active", formData.is_active ? "true" : "false");
    formDataToSend.append(
      "professional_type",
      formData.professional_type || ""
    );

    if (profilePicture) {
      formDataToSend.append("profile_picture", profilePicture);
    }

    try {
      await registerProfessional(formDataToSend);
      toast.success("Registrado com sucesso!");
      navigate("/login");
    } catch (error: any) {
      toast.error(error.message || "Erro no registro!");
    }
  };

  return (
    <div className="min-h-screen bg-white flex flex-col p-4">
      <Header />

      <div className="py-12 md:px-32 lg:px-32">
        <h1 className="text-4xl font-bold text-left mb-2">Cadastro</h1>
        <p className="font-medium text-left text-gray-600">
          Cadastre-se no maior clube de vantagens ...
        </p>
      </div>

      <div className="flex flex-col md:flex-row md:gap-6 mb-20 md:px-32 lg:px-32">
        <div className="md:order-2 md:w-1/3">
          <div className="pt-7">
            <ImageUploader
              initialImage={imagePreview || undefined}
              onImageChange={(file) => setProfilePicture(file)}
              maxFileSizeMB={MAX_FILE_SIZE_MB}
            />
            {imageErrorMessage && (
              <div className="text-red-500 mt-2">{imageErrorMessage}</div>
            )}

          </div>
        </div>

        <form onSubmit={handleSubmit} className="md:w-2/3">
          <div className="col-span-1 md:col-span-2">
            <div className="mb-4">
              <label className="block font-medium mb-1">
                <span className="text-red-500">*</span>Nome:
              </label>
              <input
                type="text"
                name="name"
                value={formData.name || ""}
                onChange={handleInputChange}
                required
                className="w-full p-2 border rounded"
                placeholder="Seu nome"
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <div className="mb-4">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>Registro Profissional:
                </label>
                <input
                  type="text"
                  name="professional_register"
                  value={formData.professional_register || ""}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                  placeholder="Número do registro profissional"
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>Data de Nascimento:
                </label>
                <input
                  type="date"
                  name="birthdate"
                  value={formData.birthdate || ""}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                  placeholder="Sua data de nascimento"
                />
              </div>

              <div className="mb-4 relative">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>Senha:
                </label>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password || ""}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 pr-10 border rounded"
                  placeholder="Sua senha"
                />
                <div
                  className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </div>

              <div className="mb-4 relative">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>Confirme sua senha:
                </label>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="w-full p-2 pr-10 border rounded"
                  placeholder="Confirme sua senha"
                />
                <div
                  className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  {showConfirmPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </div>
              {passwordErrorMessage && (
                <div className="text-red-500 text-sm mt-1">{passwordErrorMessage}</div>
              )}
            </div>

            <div>
              <div className="mb-4">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>E-mail:
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email || ""}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border rounded"
                  placeholder="Seu e-mail"
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>CPF/CNPJ:
                </label>
                <input
                  type="text"
                  name="document"
                  placeholder="Somente números"
                  value={formData.document || ""}
                  onChange={handleInputChangeDocument}
                  required
                  className="w-full p-2 border rounded"
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>Telefone:
                </label>
                <input
                  type="text"
                  name="phone"
                  value={formData.phone || ""}
                  onChange={handlePhoneInputChange}
                  required
                  className="w-full p-2 border rounded"
                  placeholder="(99)99999-9999"
                />
              </div>

              <div className="mb-4">
                <label className="block font-medium mb-1">
                  <span className="text-red-500">*</span>Área de atuação:
                </label>
                <select
                  name="professional_type"
                  value={formData.professional_type || ""}
                  onChange={handleProfessionalTypeChange}
                  required
                  className="w-full p-2 border rounded"
                >
                  <option value="" disabled>
                    Selecione
                  </option>
                  <option value="AR">Arquiteto</option>
                  <option value="EN">Engenheiro</option>
                  <option value="PA">Paisagista</option>
                  <option value="DI">Designer de Interiores</option>
                </select>
              </div>
            </div>
          </div>


          <AddressForm formValues={formData} setFormValues={setFormData} />

          <div className="mt-6">
            <div className="mb-4 flex items-center">
              <Checkbox
                isChecked={isChecked}
                handleCheckboxChange={handleCheckboxChange}
              />
              <span className="ml-2 text-sm">
                Ao concluir o cadastro você está autorizando a publicação da sua
                imagem neste site, bem como a exibição de seus dados
                profissionais.
              </span>
            </div>

            {termsErrorMessage && (
              <div className="text-red-500 mb-4">{termsErrorMessage}</div>
            )}

            <button type="submit" className="btn-primary">
              Cadastrar-se
            </button>
          </div>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default Registry;
