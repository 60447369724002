import { useEffect, useState, useRef } from "react";
import { getProfessional, getUser } from "../../services/professional-service";
import UserCircleIcon from "@heroicons/react/24/solid/UserCircleIcon";
import { useNavigate } from "react-router-dom";
import authService from "../../services/auth-Service";
import { toast } from "react-toastify";

const Avatar = () => {
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [userName, setUserName] = useState<string | null>(null);
  const [userAvatar, setUserAvatar] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const trigger = useRef<HTMLButtonElement | null>(null);
  const dropdown = useRef<HTMLDivElement | null>(null);
  const navigate = useNavigate();
  const userInfo = authService.getUserInfo();
  const isStaff = userInfo?.is_staff ?? false;

  const fetchUserData = async (userId: number) => {
    try {
      let userData;

      if (isStaff) {
        userData = await getUser(userId);
      } else {
        userData = await getProfessional(userId);
      }

      setUserName(userData.name);
      setUserAvatar(userData.profile_picture);
    } catch (error: any) {
      setErrorMessage("Erro ao carregar dados do usuário");
      toast.error(error.message || "Erro ao carregar dados do usuário.");
    }
  };

  const handleLogout = () => {
    authService.logout();
    navigate("/");
  };

  const handleProfile = () => {
    try {
      const userInfo = authService.getUserInfo();
  
      if (userInfo && userInfo.id) {
        navigate(`/profile/${userInfo.id}`);
      } else {
        throw new Error("Falha ao obter informações do usuário.");
      }
    } catch (error: any) {
      toast.error(error.message || "Erro ao obter informações do usuário.");
      authService.logout();
      navigate("/login");
    } finally {
      setDropdownOpen(false);
    }
  };

  const handleSalesList = () => {
    if (isStaff) {
      navigate(`/sales-list`);
    }
    setDropdownOpen(false);
  };

  const handleSales = () => {
    if (isStaff) {
      navigate(`/sales`);
    }
    setDropdownOpen(false);
  };

  const handleProfessionalPoints = () => {
    try {
      const userInfo = authService.getUserInfo();
  
      if (userInfo && userInfo.id) {
        navigate(`/professional-points/${userInfo.id}`);
      } else {
        throw new Error("Falha ao obter informações do usuário.");
      }
    } catch (error: any) {
      toast.error(error.message || "Erro ao obter informações do usuário.");
      authService.logout();
      navigate("/login");
    } finally {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    const userInfo = authService.getUserInfo();
    if (userInfo && userInfo.id) {
      fetchUserData(userInfo.id);
    } else {
      authService.logout();
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const clickHandler = (event: MouseEvent) => {
      const target = event.target as Node;
      if (!dropdown.current || !trigger.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      ) {
        return;
      }
      setDropdownOpen(false);
    };

    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  }, [dropdownOpen]);

  useEffect(() => {
    const keyHandler = (event: KeyboardEvent) => {
      if (!dropdownOpen || event.key !== "Escape") return;
      setDropdownOpen(false);
    };

    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  }, [dropdownOpen]);

  return (
    <section className="bg-gray-2 dark:bg-dark">
      <div className="container">
        <div className="flex justify-center">
          <div className="relative inline-block">
            <button
              ref={trigger}
              onClick={() => setDropdownOpen(!dropdownOpen)}
              className="flex items-center text-left"
            >
              <div className="relative mr-4 ml-4 h-[42px] w-[42px] rounded-full">
                {userAvatar ? (
                  <img
                    src={userAvatar}
                    alt="avatar"
                    className="h-full w-full rounded-full object-cover object-center"
                  />
                ) : (
                  <UserCircleIcon className="h-full w-full text-gray-600" />
                )}
                <span className="absolute -right-0.5 -top-0.5 block h-[14px] w-[14px] rounded-full border-[2.3px] border-white bg-[#219653] dark:border-dark"></span>
              </div>
              <span className="text-base font-medium text-dark dark:text-white">
                {userName || "Usuário"}
              </span>
              <span className="pl-[10px] text-dark duration-100 dark:text-white">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`fill-current ${
                    dropdownOpen ? "-scale-y-100" : ""
                  }`}
                >
                  <path d="M10 14.25C9.8125 14.25 9.65625 14.1875 9.5 14.0625L2.3125 7C2.03125 6.71875 2.03125 6.28125 2.3125 6C2.59375 5.71875 3.03125 5.71875 3.3125 6L10 12.5312L16.6875 5.9375C16.9688 5.65625 17.4062 5.65625 17.6875 5.9375C17.9688 6.21875 17.9688 6.65625 17.6875 6.9375L10.5 14C10.3437 14.1562 10.1875 14.25 10 14.25Z" />
                </svg>
              </span>
            </button>
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
              className={`absolute right-0 top-full z-40 w-[200px] space-y-1 rounded-lg bg-white p-2 shadow-lg dark:bg-dark-2 dark:shadow-box-dark ${
                dropdownOpen ? "block" : "hidden"
              }`}
            >
              {!isStaff && (
                <button
                  onClick={handleProfessionalPoints}
                  className="block w-full rounded px-3 py-2 text-left text-sm text-body-color hover:bg-gray-100 dark:text-dark-6 dark:hover:bg-dark-3"
                >
                  Seus Pontos
                </button>
              )}
              {!isStaff && (
                <button
                  onClick={handleProfile}
                  className="block w-full rounded px-3 py-2 text-left text-sm text-body-color hover:bg-gray-100 dark:text-dark-6 dark:hover:bg-dark-3"
                >
                  Editar Perfil
                </button>
              )}
              {isStaff && (
                <button
                  onClick={handleSales}
                  className="block w-full rounded px-3 py-2 text-left text-sm text-body-color hover:bg-gray-100 dark:text-dark-6 dark:hover:bg-dark-3"
                >
                  Cadastrar nova compra
                </button>
              )}
              {isStaff && (
                <button
                  onClick={handleSalesList}
                  className="block w-full rounded px-3 py-2 text-left text-sm text-body-color hover:bg-gray-100 dark:text-dark-6 dark:hover:bg-dark-3"
                >
                  Registro de compras
                </button>
              )}
              <button
                onClick={handleLogout}
                className="block w-full rounded px-3 py-2 text-left text-sm text-body-color hover:bg-gray-100 dark:text-dark-6 dark:hover:bg-dark-3"
              >
                Sair
              </button>
            </div>
          </div>
        </div>
      </div>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
    </section>
  );
};

export default Avatar;
