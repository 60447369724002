import React, { useState } from "react";
import { Sale } from "../shared/interfaces";
import Modal from "./modal";
import { getProfessional } from "../services/professional-service";
import {
  EnvelopeIcon,
  HomeIcon,
  IdentificationIcon,
  PhoneIcon,
  StarIcon,
} from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

interface SalesTableProps {
  sales: Sale[];
  isStaff: boolean;
}

const mapProfessionalType = (type: string) => {
  const types: { [key: string]: string } = {
    AR: "Arquiteto",
    EN: "Engenheiro",
    PA: "Paisagista",
    DI: "Designer de Interiores",
  };
  return types[type] || type;
};

const SalesTable: React.FC<SalesTableProps> = ({ sales, isStaff }) => {
  const [selectedProfessionalId, setSelectedProfessionalId] = useState<
    number | null
  >(null);
  const [professionalData, setProfessionalData] = useState<any | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleProfessionalClick = async (id: number) => {
    setSelectedProfessionalId(id);

    try {
      const data = await getProfessional(id);
      setProfessionalData(data);
      setIsModalOpen(true);
    } catch (error: any) {
      toast.error(error.message || "Erro ao buscar o profissional.");
    }
  };

  const mapStoreName = (code: string) => {
    const storeNames: { [key: string]: string } = {
      ST: "Stokcasa",
      EU: "Euromanaus",
      MS: "MS Casa",
    };
    return storeNames[code] || code;
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setProfessionalData(null);
  };

  if (sales.length === 0) {
    return <p className="text-center text-2xl py-4">Sem vendas registradas</p>;
  }

  return (
    <div className="relative overflow-x-auto">
      <table className="min-w-full bg-white border">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b text-left">Loja</th>
            {isStaff && (
              <th className="py-2 px-4 border-b text-left">Profissional</th>
            )}
            <th className="py-2 px-4 border-b text-left">Projeto</th>

            <th className="py-2 px-4 border-b text-left">Data do Projeto</th>

            {isStaff && (
              <th className="py-2 px-4 border-b text-left">Valor do Projeto</th>
            )}
            {isStaff && (
              <th className="py-2 px-4 border-b text-left">Número do Pedido</th>
            )}
            <th className="py-2 px-4 border-b text-left">Pontuação</th>
            <th className="py-2 px-4 border-b text-left">Data da Compra</th>
          </tr>
        </thead>
        <tbody>
          {sales.map((sale, index) => (
            <tr key={`${sale.id}-${index}`}>
              <td className="py-2 px-4 border-b text-left">
                {mapStoreName(sale.store)}
              </td>

              {/* Nome do profissional clicável para abrir o modal */}
              {isStaff && (
                <td
                  className="py-2 px-4 border-b text-left relative cursor-pointer hover:underline"
                  onClick={() => handleProfessionalClick(sale.professional.id)}
                >
                  {sale.professional.name}
                </td>
              )}

              <td className="py-2 px-4 border-b text-left">{sale.project}</td>
              <td className="py-2 px-4 border-b text-left">
                {sale.date_project}
              </td>
              {isStaff && (
                <td className="py-2 px-4 border-b text-left">
                  {new Intl.NumberFormat("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  }).format(sale.project_value)}
                </td>
              )}
              {isStaff && (
                <td className="py-2 px-4 border-b text-left">
                  {sale.order_number}
                </td>
              )}

              <td className="py-2 px-4 border-b text-left">{sale.points}</td>
              <td className="py-2 px-4 border-b text-left">
                {sale.created_at}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal com informações do profissional */}
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        {professionalData ? (
          <div className="flex items-start space-x-6">
            {/* Imagem do perfil com formato arredondado */}
            <img
              src={professionalData.profile_picture}
              alt={professionalData.name}
              className="w-24 h-24 rounded-full object-cover"
            />

            {/* Informações ao lado da foto */}
            <div>
              {/* Nome e tipo de profissional */}
              <h2 className="text-lg font-bold">{professionalData.name}</h2>
              <p className="text-sm text-gray-500">
                {mapProfessionalType(professionalData.professional_type)}
              </p>

              {/* Email */}
              <div className="flex items-center mt-4">
                <EnvelopeIcon className="w-5 h-5 text-gray-500 mr-2" />
                <span>{professionalData.email}</span>
              </div>

              {/* Telefone */}
              <div className="flex items-center mt-2">
                <PhoneIcon className="w-5 h-5 text-gray-500 mr-2" />
                <span>{professionalData.phone}</span>
              </div>

              {/* Endereço */}
              <div className="flex items-start mt-2">
                <HomeIcon className="w-5 h-5 text-gray-500 mr-2" />
                <div>
                  <span>
                    {professionalData.street},{" "}
                    {professionalData.residence_number}
                  </span>
                  <br />
                  <span>{professionalData.neighborhood}</span>
                  <br />
                  <span>
                    {professionalData.city} - {professionalData.state} - {professionalData.cep},
                  </span>
                  <br />
                  <span>
                    {professionalData.complement}
                  </span>
                </div>
              </div>

              {/* Documento */}
              <div className="flex items-center mt-2">
                <IdentificationIcon className="w-5 h-5 text-gray-500 mr-2" />
                <span>{professionalData.document}</span>
              </div>

              {/* Total de Pontos */}
              <div className="flex items-center mt-2">
                <StarIcon className="w-5 h-5 text-gray-500 mr-2" />
                <span>{professionalData.total_points}</span>
              </div>
            </div>
          </div>
        ) : (
          <p>Carregando...</p>
        )}
      </Modal>
    </div>
  );
};

export default SalesTable;
