import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSalesByProfessional } from "../services/salesService";
import { Sale } from "../shared/interfaces";
import SalesTable from "./sales-table";
import { StarIcon } from "@heroicons/react/24/solid";
import authService from "../services/auth-Service";

const ProfessionalPoints = () => {
  const { id } = useParams<{ id: string }>();
  const [sales, setSales] = useState<Sale[]>([]);
  const [totalPoints, setTotalPoints] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const navigate = useNavigate();

  const userInfo = authService.getUserInfo();
  const isStaff = userInfo?.is_staff ?? false;
  const userId = userInfo?.id;

  useEffect(() => {
    const loadSales = async (page: number) => {
      setLoading(true);
      try {
        const response = await getSalesByProfessional(page, id!);

        setSales((prevSales) => [...prevSales, ...response.results]);
        setTotalPoints(response.results[0]?.professional.total_points || 0);
        setHasMore(!!response.next);
      } catch (error) {
        console.error("Erro ao carregar vendas:", error);
        setErrorMessage("Erro ao carregar vendas.");
      } finally {
        setLoading(false);
      }
    };

    if (!isStaff && userId && id !== String(userId)) {
      navigate(`/professional-points/${userId}`);
      return;
    }

    loadSales(currentPage);
  }, [id, isStaff, userId, navigate, currentPage]);

  const handleLoadMore = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="bg-white flex flex-col px-4 md:px-32 lg:px-32">
      <h1 className="text-4xl font-bold mt-4">Seus Pontos</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8 mt-8 ">
        {/* Card de Pontos */}
        <div className="bg-white border border-gray-300 shadow-lg rounded-lg p-4 flex flex-col items-center justify-center text-center h-[300px] w-full md:col-span-1">
          <div className="relative h-[35px] w-[35px] rounded-full border-4 border-black flex items-center justify-center mb-4">
            <StarIcon className="h-[20px] w-[20px]" />
          </div>
          <h2 className="text-2xl font-bold mb-2">
            {totalPoints !== null ? totalPoints : "Carregando..."}
          </h2>
          <p className="text-lg font-bold">pontos</p>
          <p className="text-sm text-gray-500 mt-4">
            Ganhe mais pontos indicando compra em nossas lojas parceiras.
          </p>
        </div>

        {/* Tabela de Vendas */}
        <div className="md:col-span-2">
          <div className="bg-white border border-gray-300 shadow-lg rounded-lg p-4">
            <h2 className="text-xl font-bold mb-4">Vendas</h2>
            {sales.length > 0 ? (
              <SalesTable sales={sales} isStaff={isStaff} />
            ) : (
              <p className="text-gray-500">Sem vendas registradas.</p>
            )}
          </div>

          <div className="mt-4 flex justify-end">
            {hasMore && !loading && (
              <button className="btn-primary" onClick={handleLoadMore}>
                Ver mais
              </button>
            )}
            {loading && <p>Carregando mais vendas...</p>}
            {!hasMore && <p>Não há mais vendas.</p>}
          </div>
        </div>
      </div>

      <div className="container mx-auto pt-8 pb-16">
        <div className="flex flex-col lg:flex-row gap-8 mb-12">
          <div className="flex-1 flex flex-col justify-center items-center lg:items-start">
            <p className="text-5xl mb-4">Sobre o programa</p>
            <p className="text-3xl text-gray-600 mb-4">Como Funciona</p>
          </div>

          <div className="flex-1 flex justify-center lg:justify-end items-center">
            <img
              src="/images/CLUB-GVS_BANNER_MILAN.png"
              className="object-contain"
            />
          </div>
      </div>

        {/* Timeline Steps Component */}
        <div className="flex flex-col md:flex-row justify-between gap-8">
          {/* Step 1 */}
          <div className="flex-1">
            <div className="flex items-center mb-4">
              <div className="bg-gray-100 p-3 rounded-lg">
                <svg
                  className="w-6 h-6 text-blue-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M12 6V12L16 14"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="h-[2px] flex-1 bg-gray-200 mx-4"></div>
            </div>
            <p className="text-3xl font-bold mb-2">01</p>
            <p className="text-xl font-semibold mb-2">
              Cadastre-se em nosso site
            </p>
            <p className="text-gray-600">
              Clique em cadastro e preencha todos os dados obrigatórios.
            </p>
          </div>

          {/* Step 2 */}
          <div className="flex-1">
            <div className="flex items-center mb-4">
              <div className="bg-gray-100 p-3 rounded-lg">
                <svg
                  className="w-6 h-6 text-blue-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20 6H16V4C16 2.89543 15.1046 2 14 2H10C8.89543 2 8 2.89543 8 4V6H4C2.89543 6 2 6.89543 2 8V20C2 21.1046 2.89543 22 4 22H20C21.1046 22 22 21.1046 22 20V8C22 6.89543 21.1046 6 20 6Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="h-[2px] flex-1 bg-gray-200 mx-4"></div>
            </div>
            <p className="text-3xl font-bold mb-2">02</p>
            <p className="text-xl font-semibold mb-2">
              Desenvolva seu projeto com uma loja parceira
            </p>
            <p className="text-gray-600">
              Conte com uma de nossas lojas parceiras para atender as
              especificações dos seus projetos
            </p>
          </div>

          {/* Step 3 */}
          <div className="flex-1">
            <div className="flex items-center mb-4">
              <div className="bg-gray-100 p-3 rounded-lg">
                <svg
                  className="w-6 h-6 text-blue-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16 8L8.5 15.5L6 13"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="h-[2px] flex-1 bg-gray-200 mx-4"></div>
            </div>
            <p className="text-3xl font-bold mb-2">03</p>
            <p className="text-xl font-semibold mb-2">
              Acompanhe sua pontuação
            </p>
            <p className="text-gray-600">
              Após a efetivação da venda em uma de nossas lojas parceiras, será
              contabilizada a pontuação, podendo ser consultada através da nossa
              plataforma.
            </p>
          </div>

          {/* Step 4 */}
          <div className="flex-1">
            <div className="flex items-center mb-4">
              <div className="bg-gray-100 p-3 rounded-lg">
                <svg
                  className="w-6 h-6 text-blue-600"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22 12H18L15 21L9 3L6 12H2"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="h-[2px] flex-1 bg-gray-200 mx-4"></div>
            </div>
            <p className="text-3xl font-bold mb-2">04</p>
            <p className="text-xl font-semibold mb-2">Fechamento e Premiação</p>
            <p className="text-gray-600">
              Após o término da campanha, será divulgado o resultado com os
              contemplados da edição vigente.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfessionalPoints;
