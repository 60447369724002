import { useEffect, useState } from "react";
import { Sale } from "../shared/interfaces";
import { useNavigate } from "react-router-dom";
import SalesTable from "./sales-table";
import authService from "../services/auth-Service";
import Pagination from "./ui-components/pagination";
import { getAllSalesSearch } from "../services/salesService";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import { toast } from "react-toastify";

const SalesList = () => {
  const [sales, setSales] = useState<Sale[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [hasNext, setHasNext] = useState<boolean>(false);
  const [hasPrevious, setHasPrevious] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const pageSize = 10;

  const userInfo = authService.getUserInfo();
  const isStaff = userInfo?.is_staff ?? false;

  const fetchSales = async (page: number, search: string = "") => {
    try {
      setLoading(true);
      const salesData = await getAllSalesSearch(page, search);
      setSales(salesData.results);
      setTotalPages(Math.ceil(salesData.count / pageSize));
      setHasNext(salesData.next !== null);
      setHasPrevious(salesData.previous !== null);
      setLoading(false);
    } catch (err: any) {
      const errorMessage = err.response?.data?.message || "Erro ao carregar as vendas";
      toast.error(errorMessage);
      setError(errorMessage);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSales(currentPage, searchTerm);
  }, [currentPage]);

  const handleSearch = () => {
    setCurrentPage(1);
    fetchSales(1, searchTerm);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  return (
    <div className="p-4 md:px-32 lg:px-32">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-4xl font-bold">Registro de compras</h1>
        <button
          onClick={() => navigate("/sales")}
          className="btn-primary px-4 py-2 rounded"
        >
          Cadastrar nova compra
        </button>
      </div>

      {/* Caixa de Pesquisa */}
      <div className="mt-6 relative">
        <div className="block mb-2">
          <div className="relative">
            <MagnifyingGlassIcon
              className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
              onClick={handleSearch}
            />
            <input
              type="text"
              className="w-full p-2 pl-10 border border-gray-300 rounded mt-1"
              placeholder="Pesquisar por nome do profissional ou CPF/CNPJ"
              value={searchTerm}
              onChange={handleSearchChange}
              onKeyPress={handleKeyPress}
            />
          </div>
        </div>
      </div>

      {/* Mensagem de erro, se houver */}
      {error && <p className="text-red-500">{error}</p>}

      {/* Tabela de Vendas */}
      {loading ? (
        <p>Carregando vendas...</p>
      ) : (
        <>
          <div className="w-full">
            <SalesTable sales={sales} isStaff={isStaff} />
          </div>
          <div className="flex justify-end mt-4">
            <Pagination
              currentPage={currentPage}
              totalPages={totalPages}
              hasNext={hasNext}
              hasPrevious={hasPrevious}
              onPageChange={handlePageChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SalesList;
