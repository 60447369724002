import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import authService from "./auth-Service";

interface PrivateRouteProps {
  element: React.ComponentType;
  requiredStaff?: boolean;
}

const PrivateRouteService: FC<PrivateRouteProps> = ({
  element: Component,
  requiredStaff = false,
}) => {

  const token = authService.getAccessToken();

  if (!token) {
    return <Navigate to="/" replace />;
  }

  try {
    const decodedToken: {
      user_id: string;
      is_staff: boolean;
      exp: number;
      token_type: string;
    } = jwtDecode(token);
    const userId = decodedToken.user_id;
    const isStaff = decodedToken.is_staff;

    if (decodedToken.token_type !== "access") {
      return <Navigate to="/login" replace />;
    }

    if (requiredStaff && !isStaff) {
      return <Navigate to={`/professional-points/${userId}`} replace />;
    }
    return <Component />;
  } catch (error) {
    return <Navigate to="/login" replace />;
  }
};

export default PrivateRouteService;
