import Avatar from "./ui-components/avatar";

const HandleLoggedInHeader = () => {

  return (
    <header className="flex flex-col items-center bg-white p-4 md:px-32 lg:px-32">
  <div className="flex w-full justify-between">
    <div className="flex">
      <div
        className="bg-cover bg-center w-[170px] h-[50px] sm:w-[183.08px] sm:h-[56px] lg:w-[200px] lg:h-[60px]"
        style={{
          backgroundImage: "url('/images/header-clubgvs1.png')",
        }}
      ></div>
    </div>

    {/* <div className="sm:flex space-x-4">
      <button className="text-gray-700 hover:text-gray-900">Dashboard</button>
      <button className="text-gray-700 hover:text-gray-900">Extrato de pontos</button>
    </div> */}

    <div className="flex items-center">
      <Avatar />
    </div>
  </div>
  <div className="border-b border-gray-300 w-full mt-4"></div>
</header>

  );
};

export default HandleLoggedInHeader;