import authService from "./auth-Service";

const API_URL = "/professionals/";
const API_URL_USER = "/users/";
const API_URL_POST = `${process.env.REACT_APP_API_URL}/professionals/`;
const API_URL_TOP_PROFESSIONALS = `${process.env.REACT_APP_API_URL}/professionals/top_professionals/`;

const handleResponse = (response: any) => {
  const data = response.data;

  if (response.status >= 200 && response.status < 300) {
    return data;
  } else {
    const errorDetails = data || { message: "Erro desconhecido" };
    throw new Error(errorDetails.message || "Erro desconhecido");
  }
};

export const getTopProfessionals = async (): Promise<any> => {
  try {
    const response = await fetch(API_URL_TOP_PROFESSIONALS, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Erro na requisição: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Erro na requisição de top profissionais:", error);
    throw error;
  }
};

export const registerProfessional = async (data: FormData): Promise<any> => {
  try {
    const response = await fetch(`${API_URL_POST}`, {
      method: "POST",
      body: data,
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.errors) {
        const fieldErrors = Object.keys(errorData.errors).map((key) => {
          return `${errorData.errors[key][0]}`;
        });

        throw new Error(fieldErrors.join(" "));
      }
      if (errorData.non_field_errors) {
        throw new Error(errorData.non_field_errors[0]);
      }
      throw new Error("Erro ao registrar profissional.");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error:any) {
    throw new Error(error.message || "Erro ao registrar profissional.");
  }
};

export const getProfessional = async (id: number): Promise<any> => {
  try {
    const response = await authService.authenticateRequest(
      "GET",
      `${API_URL}${id}/`
    );

    if (response.status !== 200) {
      const errorData = response.data;

      if (errorData.errors) {
        const fieldErrors = Object.keys(errorData.errors).map((key) => {
          return `${key}: ${errorData.errors[key][0]}`;
        });
        throw new Error(fieldErrors.join(", "));
      }

      if (errorData.non_field_errors) {
        throw new Error(errorData.non_field_errors[0]);
      }

      throw new Error("Erro ao obter os dados do profissional.");
    }

    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "Erro ao obter os dados do profissional.";
    throw new Error(errorMessage);
  }
};
export const getUser = async (id: number): Promise<any> => {
  try {
    const response = await authService.authenticateRequest(
      "GET",
      `${API_URL_USER}${id}/`
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Erro na requisição:", error);
    throw error;
  }
};

export const updateProfessional = async (
  id: number,
  data: FormData
): Promise<any> => {
  try {
    const response = await authService.authenticateRequest(
      "PUT",
      `${API_URL}${id}/`,
      data
    );

    if (![200, 204].includes(response.status)) {
      const errorData = response.data;

      if (errorData.errors) {
        const fieldErrors = Object.keys(errorData.errors).map((key) => {
          return `${key}: ${errorData.errors[key][0]}`;
        });
        throw new Error(fieldErrors.join(", "));
      }

      if (errorData.non_field_errors) {
        throw new Error(errorData.non_field_errors[0]);
      }

      throw new Error("Erro ao atualizar os dados do profissional.");
    }

    return handleResponse(response);
  } catch (error: any) {
    const errorMessage = error.response?.data?.message || "Erro ao atualizar os dados do profissional.";
    throw new Error(errorMessage);
  }
};

export const updateUser = async (id: number, data: FormData): Promise<any> => {
  try {
    const response = await authService.authenticateRequest(
      "PUT",
      `${API_URL}${id}/`,
      data
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Erro na requisição:", error);
    throw error;
  }
};

export const deleteProfessional = async (id: number): Promise<any> => {
  try {
    const response = await authService.authenticateRequest(
      "DELETE",
      `${API_URL}${id}/`
    );
    return handleResponse(response);
  } catch (error) {
    console.error("Erro na requisição:", error);
    throw error;
  }
};

export const searchProfessionals = async (searchTerm: string): Promise<any> => {
  try {
    const response = await authService.authenticateRequest(
      "GET",
      `${API_URL}?document=${searchTerm}`
    );

    if (response.status !== 200) {
      const errorData = response.data;

      if (errorData.errors) {
        const fieldErrors = Object.keys(errorData.errors).map((key) => {
          return `${key}: ${errorData.errors[key][0]}`;
        });
        throw new Error(fieldErrors.join(", "));
      }

      if (errorData.non_field_errors) {
        throw new Error(errorData.non_field_errors[0]);
      }

      throw new Error("Erro ao buscar profissionais.");
    }

    return handleResponse(response);
  } catch (error: any) {
    console.error("Erro na requisição de busca:", error);
    
    const errorMessage = error.response?.data?.message || "Erro ao buscar profissionais.";
    throw new Error(errorMessage);
  }
};
