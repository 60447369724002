export const validateImageSizeAndFormat = (file: File, maxSizeMB: number): string | null => {
  const maxSize = maxSizeMB * 1024 * 1024;
  const allowedFormats = ["image/jpeg", "image/png"];

  if (file.size > maxSize) {
    return `O tamanho da imagem deve ser inferior a ${maxSizeMB} MB.`;
  }

  if (!allowedFormats.includes(file.type)) {
    return "Formato de imagem inválido. Apenas PNG ou JPEG/JPG são permitidos.";
  }

  return null;
};
