interface CheckboxProps {
  isChecked: boolean;
  handleCheckboxChange: (e: any) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  isChecked,
  handleCheckboxChange,
}) => {
  return (
    <label className="flex items-center cursor-pointer select-none text-dark dark:text-white">
      <div className="relative">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
          className="sr-only"
          name="is_active"
          required
        />
        <div className="box mr-4 flex h-5 w-5 items-center justify-center rounded border-stroke dark:border-dark-3 border">
          <span
            className={`dot h-[10px] w-[10px] rounded-sm ${
              isChecked && "bg-primary"
            }`}
          ></span>
        </div>
      </div>
    </label>
  );
};

export default Checkbox;
