import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Keyboard, Scrollbar, Navigation, Pagination } from "swiper/modules";

import Header from "./header";
import Footer from "./footer";
import { getTopProfessionals } from "../services/professional-service";
import { Professional } from "../shared/interfaces";
import { CheckCircleIcon, UserCircleIcon } from "@heroicons/react/24/solid";

const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_BASE_URL;

const LandingPage = () => {
  const navigate = useNavigate();
  const [professionals, setProfessionals] = useState<Professional[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTopProfessionals = async () => {
      try {
        const data = await getTopProfessionals();
        setProfessionals(
          Array.isArray(data)
            ? data.map((professional) => ({
              ...professional,
              profile_picture: professional.profile_picture.includes("https")
                ? professional.profile_picture
                : `${CLOUDINARY_URL}${professional.profile_picture}`,
            }))
            : []
        );
        setLoading(false);
      } catch (error) {
        console.error("Erro ao buscar profissionais:", error);
        setProfessionals([]);
        setLoading(false);
      }
    };

    fetchTopProfessionals();
  }, []);

  return (
    <div className="p-4">
      <Header />
      <div className="md:px-32 lg:px-32">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {/* Coluna da esquerda - Texto e botão */}
          <div className="flex items-center justify-center md:justify-start p-4">
            <div>
              <p className="text-4xl md:text-7xl font-bold">ClubGVS</p>
              <p className="text-4xl md:text-7xl font-bold mb-6">
                Arquitetura e Design
              </p>
              <p className="text mb-6">
                Seu melhor clube de benefícios a um clique
              </p>
              <button
                className="btn-primary px-4 py-2 rounded mb-4"
                onClick={() => navigate("/registry")}
              >
                Cadastre-se
              </button>
            </div>
          </div>

          {/* Coluna da direita - Imagem */}
          <div className="flex items-center justify-center md:justify-end pb-4">
            <img
              src="/images/CIMA_FOTO_GVS.png"
              alt="Parceiro 2"
              className="w-full md:w-3/4 h-auto rounded-xl"
            />
          </div>
        </div>

        {/* Carousel de Profissionais Destaque */}
        <div className="p-4">
          <p className="text-4xl font-bold text-center mb-10">
            Profissionais Destaque
          </p>

          {!loading && professionals.length > 0 ? (
            <Swiper
              spaceBetween={10}
              slidesPerView={5}
              navigation={true}
              pagination={{ clickable: true }}
              className="mySwiper"
              modules={[Keyboard, Scrollbar, Navigation, Pagination]}
              breakpoints={{
                160: {
                  slidesPerView: 1,
                },
                720: {
                  slidesPerView: 2,
                },
                1080: {
                  slidesPerView: 3,
                },
                1920: {
                  slidesPerView: 5,
                },
              }}
            >
              {professionals.map((professional) => (
                <SwiperSlide key={professional.id}>
                  <div className="relative mb-8 ml-8 border border-gray-300 shadow-lg rounded-lg w-[270px]">
                    <div className="relative rounded-lg overflow-hidden">
                      {professional.profile_picture ? (
                        <img
                          src={professional.profile_picture}
                          alt={professional.name}
                          className="object-cover h-[320px] w-full"
                        />
                      ) : (
                        <UserCircleIcon className="text-gray-400 w-full h-[320px]" />
                      )}

                      <div className="absolute inset-x-0 bottom-4 mx-4 bg-white p-1 shadow-lg rounded-lg">
                        <h3 className="text-sm font-bold text-center">
                          {professional.name}
                        </h3>
                        <p className="text-gray-500 text-sm text-center">
                          {professional.professional_type}
                        </p>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          ) : (
            <p className="text-gray-500 text-lg text-center">
              {loading
                ? "Carregando..."
                : "Quer ver sua foto aqui? Cadastre-se, acumule pontos para ganhar prêmios exclusivos!"}
            </p>
          )}
        </div>

        {/* Após o Carousel */}
        <div className="grid grid-cols-1 md:grid-cols-2">
          {/* Imagens menores à esquerda */}
          <div className="flex flex-wrap justify-center md:justify-center items-center md:items-center gap-4">
            <div className="flex flex-col gap-4">
              <div className="w-72 h-64 overflow-hidden rounded-lg mx-auto md:mx-0">
                <img
                  src="/images/BAIXO_FOTO_GVS.png"
                  className="w-full h-full object-cover"
                />
              </div>
              <div className="w-72 h-64 overflow-hidden rounded-lg mx-auto md:mx-0">
                <img
                  src="/images/Banner_GVS_03.png"
                  alt="Pessoa usando óculos e camisa jeans"
                  className="w-full h-full object-cover"
                />
              </div>
            </div>
            <div className="w-80 h-96 overflow-hidden rounded-lg mt-0 mx-auto md:mx-0">
              <img
                src="/images/Banner_GVS_02.png"
                className="w-full h-full object-cover"
              />
            </div>
          </div>

          {/* Grid da Direita - Título e Texto */}
          <div className="flex items-center justify-center p-4">
            <div>
              <p className="text-4xl font-bold mb-4">Conheça o ClubGVS</p>
              <p className="mb-6">
                O ClubGVS é um programa que fortalece a parceria entre lojas
                parceiras e profissionais de arquitetura, decoração e design de
                interiores. Aproveite a chance de expandir suas possibilidades
                no mercado. Como membro do clube, você terá:
              </p>

              <ul className="list-none pl-0">
                <li className="mb-2 flex items-center">
                  <CheckCircleIcon className="h-6 w-6 text-gray-500 mr-2 flex-shrink-0" />
                  Acesso a oportunidades e experiências diferenciadas.
                </li>
                <li className="mb-2 flex items-center">
                  <CheckCircleIcon className="h-6 w-6 text-gray-500 mr-2 flex-shrink-0" />
                  Benefícios exclusivos.
                </li>
                <li className="mb-2 flex items-center">
                  <CheckCircleIcon className="h-6 w-6 text-gray-500 mr-2 flex-shrink-0" />
                  Valorização e incentivo a suas colaborações.
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Seção de Empresas Parceiras */}
        <div className="m-16">
          <p className="text-4xl font-bold text-center mb-10">
            Empresas Parceiras
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className="flex items-center justify-center">
              <img
                src="/images/Euromanaus_Logo.png"
                alt="Parceiro 1"
                className="h-auto m-4"
              />
            </div>

            <div className="flex items-center justify-center">
              <img
                src="/images/MSCASA_Logo.png"
                alt="Parceiro 2"
                className="h-auto p-4"
              />
            </div>

            <div className="flex items-center justify-center">
              <img
                src="/images/Stokcasa_Logo.png"
                alt="Parceiro 3"
                className="h-auto p-4"
              />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default LandingPage;
