import axios, { Method } from "axios";
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.REACT_APP_API_URL;

interface JwtPayload {
  id: number;
  is_staff: boolean;
  exp: number;
}

interface LoginResponse {
  token: string;
}

interface ResetPasswordResponse {
  detail: string;
}

const login = async (email: string, password: string): Promise<LoginResponse> => {
  try {
    const response = await axios.post(`${API_URL}/token/`, {
      email,
      password,
    });

    sessionStorage.setItem("access_token", response.data.access);
    sessionStorage.setItem("refresh_token", response.data.refresh);

    return {
      token: response.data.access,
    };
  } catch (error: any) {
    if (error.response && error.response.data) {
      const errorData = error.response.data;
      if (errorData.non_field_errors) {
        throw new Error(errorData.non_field_errors[0]);
      }
      const fieldErrors = Object.keys(errorData).map(
        (key) => `${key}: ${errorData[key][0]}`
      );
      throw new Error(fieldErrors.join(', '));
    }
    throw new Error("Falha no login. Verifique suas credenciais.");
  }
};

const logout = (): void => {
  sessionStorage.removeItem("access_token");
  sessionStorage.removeItem("refresh_token");
};

const getAccessToken = (): string | null => {
  return sessionStorage.getItem("access_token");
};

const getRefreshToken = (): string | null => {
  return sessionStorage.getItem("refresh_token");
};

const isTokenValid = (token: string | null): boolean => {
  if (!token) return false;

  try {
    const tokenPayload: JwtPayload = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    return tokenPayload.exp > currentTime;
  } catch (error) {
    return false;
  }
};

const refreshAccessToken = async (): Promise<string> => {
  const refresh_token = getRefreshToken();

  if (!refresh_token) {
    throw new Error("Token de refresh não encontrado. É necessário fazer login novamente.");
  }

  try {
    const response = await axios.post(`${API_URL}/token/refresh/`, {
      refresh: refresh_token,
    });

    const newAccessToken = response.data.access;
    sessionStorage.setItem("access_token", newAccessToken);

    if (response.data.refresh) {
      const newRefreshToken = response.data.refresh;
      sessionStorage.setItem("refresh_token", newRefreshToken);
    }

    return newAccessToken;
  } catch (error) {
    throw new Error("Falha ao renovar o token de acesso. Faça login novamente.");
  }
};

const authenticateRequest = async (
  method: Method,
  url: string,
  data: any = null
): Promise<any> => {
  let token = getAccessToken();

  if (!isTokenValid(token)) {
    token = await refreshAccessToken();
  }

  try {
    const response = await axios({
      method,
      url: `${API_URL}${url}`,
      data,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  } catch (error: any) {
    if (error.response && error.response.status === 401) {
      try {
        token = await refreshAccessToken();
        const retryResponse = await axios({
          method,
          url: `${API_URL}${url}`,
          data,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return retryResponse;
      } catch (refreshError) {
        logout();
        throw new Error("Sessão expirada. Faça login novamente.");
      }
    } else {
      throw error;
    }
  }
};

const resetPassword = async (document: string, newPassword: string): Promise<ResetPasswordResponse> => {
  try {
    const response = await fetch(`${API_URL}/password-reset/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ document, new_password: newPassword }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      if (errorData.document) {
        throw new Error(errorData.document[0]);
      }
      const fieldErrors = Object.keys(errorData).map(
        (key) => `${key}: ${errorData[key][0]}`
      );
      throw new Error(fieldErrors.join(', '));
    }
    return response.json();
  } catch (error: any) {
    throw new Error(error.message || 'Falha na redefinição de senha.');
  }
};

const changePassword = async (
  currentPassword: string,
  newPassword: string,
  confirmNewPassword: string
): Promise<any> => {
  let token = getAccessToken();

  if (!isTokenValid(token)) {
    token = await refreshAccessToken();
  }

  try {
    const response = await axios.post(
      `${API_URL}/password-change/`,
      {
        current_password: currentPassword,
        new_password: newPassword,
        confirm_new_password: confirmNewPassword,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error: any) {
    if (error.response) {
      const errorMessage = error.response.data?.message || "Erro ao alterar a senha.";
      throw new Error(errorMessage);
    } else {
      throw new Error("Erro desconhecido ao tentar alterar a senha.");
    }
  }
};


const getUserInfo = (): JwtPayload | null => {
  const token = getAccessToken();
  if (token) {
    try {
      return jwtDecode<JwtPayload>(token);
    } catch (error) {
      console.error('Erro ao decodificar o token', error);
      return null;
    }
  }
  return null;
};

const authService = {
  login,
  logout,
  getAccessToken,
  authenticateRequest,
  refreshAccessToken,
  resetPassword,
  getUserInfo,
  changePassword,
};

export default authService;
