import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  getProfessional,
  updateProfessional,
} from "../services/professional-service";
import { Professional } from "../shared/interfaces";
import authService from "../services/auth-Service";
import {
  EyeIcon,
  EyeSlashIcon,
} from "@heroicons/react/24/solid";
import AddressForm from "./address-form";
import { toast } from "react-toastify";
import ImageUploader from "./ui-components/image-uploader";

const MAX_FILE_SIZE_MB = 1;

const Profile = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [userInfo] = useState(authService.getUserInfo());

  const [professional, setProfessional] = useState<Professional | null>(null);
  const [formValues, setFormValues] = useState<Partial<Professional>>({});
  const [profilePicture, setProfilePicture] = useState<File | null>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [, setErrorMessage] = useState<string | null>(null);
  const [imageErrorMessage, setImageErrorMessage] = useState<string | null>(
    null
  );
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState<
    string | null
  >(null);
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);

  useEffect(() => {
    if (userInfo && id && userInfo.id !== Number(id)) {
      navigate(`/profile/${userInfo.id}`);
      return;
    }

    const fetchProfessional = async () => {
      if (!id || professional) return;

      try {
        const numericId = Number(id);
        const data = await getProfessional(numericId);
        setProfessional(data);
        setFormValues({
          email: data.email,
          document: data.document,
          phone: data.phone,
          cep: data.cep,
          state: data.state,
          city: data.city,
          neighborhood: data.neighborhood,
          residence_number: data.residence_number,
          street: data.street,
          name: data.name,
          professional_register: data.professional_register,
          professional_type: data.professional_type,
          complement: data.complement,
        });
        setPreviewImage(data.profile_picture || null);
      } catch (error) {
        console.error("Erro ao buscar dados do profissional", error);
        setErrorMessage("Erro ao carregar dados do profissional");
      }
    };

    fetchProfessional();
  }, [id, userInfo, professional]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handlePhoneInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let phone = e.target.value;

    phone = phone.replace(/\D/g, "");

    if (phone.length > 0) {
      phone = phone.replace(/^(\d{2})(\d)/g, "($1)$2");
    }
    if (phone.length >= 9) {
      phone = phone.replace(/(\d{5})(\d)/, "$1-$2");
    }

    setFormValues({
      ...formValues,
      phone,
    });
  };

  const handleInputChangeDocument = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    let document = e.target.value;

    document = document.replace(/\D/g, "");

    setFormValues({
      ...formValues,
      document,
    });
  };

  const handleProfessionalTypeChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      professional_type: value,
    }));
  };


  const handleClearImage = () => {
    setPreviewImage(null);
    setProfilePicture(null);
    setImageErrorMessage(null);
  };

  const handleChangePassword = async () => {
    try {
      if (newPassword !== confirmNewPassword) {
        setPasswordErrorMessage("As senhas não coincidem.");
        return;
      }

      const response = await authService.changePassword(
        currentPassword,
        newPassword,
        confirmNewPassword
      );
      setCurrentPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
      setPasswordErrorMessage(null);
      toast.success(response.detail);
    } catch (error: any) {
      const errorMessage =
        error.detail || "Erro ao alterar a senha.";
      setPasswordErrorMessage(errorMessage);
      toast.error(errorMessage);
    }
  };

  const handleSave = async () => {
    if (!id) return;

    const numericId = Number(id);
    try {
      const updatedFormData = new FormData();
      updatedFormData.append("email", formValues.email || "");
      updatedFormData.append("document", formValues.document || "");
      updatedFormData.append("phone", formValues.phone || "");
      updatedFormData.append("cep", formValues.cep || "");
      updatedFormData.append("state", formValues.state || "");
      updatedFormData.append("city", formValues.city || "");
      updatedFormData.append("neighborhood", formValues.neighborhood || "");
      updatedFormData.append("complement", formValues.complement || "");
      updatedFormData.append("professional_register", formValues.professional_register || "");
      updatedFormData.append(
        "residence_number",
        formValues.residence_number || ""
      );
      updatedFormData.append("street", formValues.street || "");
      updatedFormData.append("name", formValues.name || "");
      updatedFormData.append(
        "professional_type",
        formValues.professional_type || ""
      );

      if (profilePicture) {
        updatedFormData.append("profile_picture", profilePicture);
      }

      const updatedProfessional = await updateProfessional(
        numericId,
        updatedFormData
      );
      setProfessional(updatedProfessional);
      setFormValues({
        email: updatedProfessional.email,
        document: updatedProfessional.document,
        phone: updatedProfessional.phone,
        address: updatedProfessional.address,
        name: updatedProfessional.name,
        professional_type: updatedProfessional.professional_type,
        professional_register: updatedProfessional.professional_register,
      });
      setPreviewImage(updatedProfessional.profile_picture);
      toast.success(updatedProfessional.detail);

      if (userInfo) {
        navigate(`/professional-points/${userInfo.id}`);
      }
    } catch (error: any) {
      const errorMessage =
        error.detail;
      toast.error(errorMessage || "Erro ao atualizar os dados do profissional");
    }
  };

  return (
    <div className="px-8 md:px-32 lg:px-32">
      <div className="bg-white flex flex-col">
        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4 mb-8 mt-8">
          {/* Card da Foto e Dados Pessoais */}
          <div className="bg-white shadow-lg rounded-lg p-16 col-span-2">
            <h2 className="text-4xl font-bold mb-4">Dados Pessoais</h2>

            <div className="grid grid-cols-1 xl:grid-cols-3 gap-8">
              {/* Coluna da Foto */}
              <div className="pt-7 order-first xl:order-none">
                  <ImageUploader
                    initialImage={previewImage || undefined}
                    onImageChange={(file) => setProfilePicture(file)}
                    maxFileSizeMB={MAX_FILE_SIZE_MB}
                  />
                  {imageErrorMessage && (
                    <div className="text-red-500 mt-2">{imageErrorMessage}</div>
                  )}
              </div>

              {/* Coluna de Informações Pessoais */}
              <div className="md:col-span-2">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <label className="block mb-4">
                    <span className="text-red-500">*</span>Nome:
                    <input
                      type="text"
                      name="name"
                      value={formValues.name || ""}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
                    />
                  </label>
                  <label className="block mb-4">
                    <span className="text-red-500">*</span>Registro Profissional:
                    <input
                      type="text"
                      name="professional_register"
                      value={formValues.professional_register || ""}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
                    />
                  </label>
                  <label className="block mb-4">
                    <span className="text-red-500">*</span>Área de atuação:
                    <select
                      name="professional_type"
                      value={formValues.professional_type || ""}
                      onChange={handleProfessionalTypeChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
                    >
                      <option value="" disabled>
                        Selecione
                      </option>
                      <option value="AR">Arquiteto</option>
                      <option value="EN">Engenheiro</option>
                      <option value="PA">Paisagista</option>
                      <option value="DI">Designer de Interiores</option>
                    </select>
                  </label>
                  <label className="block mb-4">
                    <span className="text-red-500">*</span>E-mail:
                    <input
                      type="text"
                      name="email"
                      value={formValues.email || ""}
                      onChange={handleInputChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
                    />
                  </label>
                  <label className="block mb-4">
                    <span className="text-red-500">*</span>CPF/CNPJ:
                    <input
                      type="text"
                      name="document"
                      value={formValues.document || ""}
                      placeholder="Somente números"
                      onChange={handleInputChangeDocument}
                      className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
                    />
                  </label>
                  <label className="block mb-4">
                    <span className="text-red-500">*</span>Telefone:
                    <input
                      type="text"
                      name="phone"
                      value={formValues.phone || ""}
                      onChange={handlePhoneInputChange}
                      className="w-full p-2 border border-gray-300 rounded mt-1 text-black"
                    />
                  </label>
                </div>
                <AddressForm
                  formValues={formValues}
                  setFormValues={setFormValues}
                />
              </div>
            </div>

            <div className="flex mt-6">
              <button onClick={handleSave} className="btn-primary w-full">
                Salvar
              </button>
            </div>
          </div>

          {/* Card de Mudança de Senha */}
          <div className="bg-white shadow-lg rounded-lg p-16">
            <h2 className="text-4xl font-bold mb-4">Senha</h2>
            <div className="grid grid-cols-1 gap-4">
              {/* Campo Senha Atual */}
              <label className="block mb-4 relative">
                <span className="text-red-500">*</span>Senha Atual:
                <input
                  type={showCurrentPassword ? "text" : "password"}
                  name="current_password"
                  value={currentPassword}
                  onChange={(e) => setCurrentPassword(e.target.value)}
                  className="w-full p-2 pr-10 border border-gray-300 rounded mt-1 text-black"
                />
                <div
                  className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                >
                  {showCurrentPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </label>

              {/* Campo Nova Senha */}
              <label className="block mb-4 relative">
                <span className="text-red-500">*</span>Nova Senha:
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="new_password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-2 pr-10 border border-gray-300 rounded mt-1 text-black"
                />
                <div
                  className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  {showNewPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </label>

              {/* Campo Repetir Nova Senha */}
              <label className="block mb-4 relative">
                <span className="text-red-500">*</span>Repetir Nova Senha:
                <input
                  type={showConfirmNewPassword ? "text" : "password"}
                  name="confirm_new_password"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  className="w-full p-2 pr-10 border border-gray-300 rounded mt-1 text-black"
                />
                <div
                  className="absolute inset-y-0 right-0 pt-6 pr-3 flex items-center cursor-pointer"
                  onClick={() =>
                    setShowConfirmNewPassword(!showConfirmNewPassword)
                  }
                >
                  {showConfirmNewPassword ? (
                    <EyeSlashIcon className="h-5 w-5 text-gray-500" />
                  ) : (
                    <EyeIcon className="h-5 w-5 text-gray-500" />
                  )}
                </div>
              </label>

              {passwordErrorMessage && (
                <div className="text-red-500">{passwordErrorMessage}</div>
              )}
            </div>

            <div className="flex mt-6 pt-8">
              <button
                onClick={handleChangePassword}
                className="btn-primary w-full"
              >
                Alterar Senha
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
