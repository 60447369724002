import { Sale } from "../shared/interfaces";
import authService from "./auth-Service";
interface PaginatedSalesResponse {
  results: Sale[];
  next: string | null;
  previous: string | null;
  count: number;
}

export interface SaleCreate {
  store: string;
  project: string;
  date_project: string;
  order_number: string;
  project_value: number;
  document: string;
}

const API_URL = "/sales/";

export const getSales = async (
  page: number = 1
): Promise<PaginatedSalesResponse> => {
  try {
    const response = await authService.authenticateRequest(
      "GET",
      `${API_URL}?page=${page}`
    );

    if (response.status !== 200) {
      throw new Error("Erro ao carregar as vendas");
    }

    return {
      results: response.data.results,
      next: response.data.next,
      previous: response.data.previous,
      count: response.data.count,
    };
  } catch (error) {
    throw error;
  }
};

export const getAllSales = async (
  page: number = 1
): Promise<PaginatedSalesResponse> => {
  try {
    const response = await authService.authenticateRequest(
      "GET",
      `${API_URL}?page=${page}`
    );

    if (response.status !== 200) {
      throw new Error("Erro ao carregar as vendas");
    }

    return {
      results: response.data.results,
      next: response.data.next,
      previous: response.data.previous,
      count: response.data.count,
    };
  } catch (error) {
    throw error;
  }
};

export const getAllSalesSearch = async (
  page: number = 1,
  searchTerm: string = ""
): Promise<PaginatedSalesResponse> => {
  try {
    let url = `${API_URL}?page=${page}`;
    if (searchTerm) {
      url += `&professional_name_or_document=${encodeURIComponent(searchTerm)}`;
    }
    const response = await authService.authenticateRequest("GET", url);

    if (response.status !== 200) {
      throw new Error("Erro ao carregar as vendas");
    }

    return {
      results: response.data.results,
      next: response.data.next,
      previous: response.data.previous,
      count: response.data.count,
    };
  } catch (error: any) {
    if (error.response) {
      throw new Error(error.response.data.message || "Erro ao carregar as vendas.");
    } else {
      throw new Error(error.message || "Erro de comunicação com o servidor.");
    }
  }
};

export const getSalesByProfessional = async (
  page: number = 1,
  professionalId: string
) => {
  try {
    const response = await authService.authenticateRequest(
      "GET",
      `/sales/?page=${page}&professional_id=${professionalId}`
    );

    if (response.status !== 200) {
      throw new Error("Erro ao carregar as vendas");
    }

    return {
      results: response.data.results,
      next: response.data.next,
      previous: response.data.previous,
      count: response.data.count,
    };
  } catch (error) {
    console.error("Erro na requisição de vendas:", error);
    throw error;
  }
};

export const addSale = async (saleData: SaleCreate) => {
  try {
    const response = await authService.authenticateRequest(
      "POST",
      API_URL,
      saleData
    );

    if (response.status !== 201) {
      const errorMessage = response.data?.message || "Erro ao adicionar a venda";
      throw new Error(errorMessage);
    }

    return response.data;
  } catch (error: any) {
    console.error("Erro ao adicionar a venda:", error);

    const errorMessage = error.response?.data?.message || "Erro ao adicionar a venda.";
    throw new Error(errorMessage);
  }
};
