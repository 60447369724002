import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate("/login");
  };
  const handleRegistry = () => {
    navigate("/registry");
  };
  const handleLadingPage = () => {
    navigate("/");
  };

  return (
    <header className="flex items-center justify-between bg-white p-4 md:px-32 lg:px-32">
      <div className="flex">
        <div
          className="bg-cover bg-center w-[170px] h-[50px] sm:w-[183.08px] sm:h-[56px] lg:w-[200px] lg:h-[60px] cursor-pointer"
          onClick={handleLadingPage}
          style={{
            backgroundImage: "url('/images/header-clubgvs1.png')",
          }}
        ></div>
      </div>

      {/* <div className="sm:flex space-x-4">
        <button className="text-gray-700 hover:text-gray-900">Sobre</button>
        <button className="text-gray-700 hover:text-gray-900">Eventos</button>
      </div> */}

      <div className="flex items-center">
        <button
          className={`py-2 px-4 sm:py-3 sm:px-6 md:py-2 md:px-10 lg:py-2 lg:px-12 text-sm sm:text-base md:text-base lg:text-base cursor-pointer`}
          onClick={handleLogin}
        >
          Login
        </button>
        <button
          className={`btn-primary py-2 px-4 sm:py-3 sm:px-6 md:py-2 md:px-10 lg:py-2 lg:px-12 text-sm sm:text-base md:text-base lg:text-base cursor-pointer`}
          onClick={handleRegistry}
        >
          Cadastre-se
        </button>
      </div>
    </header>
  );
};

export default Header;
