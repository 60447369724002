import { useState, useEffect } from "react";
import { addSale } from "../services/salesService";
import { searchProfessionals } from "../services/professional-service";
import {
  EnvelopeIcon,
  HomeIcon,
  IdentificationIcon,
  MagnifyingGlassIcon,
  PhoneIcon,
  StarIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Sales = () => {
  const [document, setDocument] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [orderNumber, setOrderNumber] = useState<string>("");
  const [project, setProject] = useState<string>("");
  const [projectValue, setProjectValue] = useState<string>("");
  const [store, setStore] = useState<string>("");
  const [dateProject, setDateProject] = useState<string>("");
  const [professionalData, setProfessionalData] = useState<any>(null);
  const [rawProjectValue, setRawProjectValue] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    if (errorMessage) {
      const timer = setTimeout(() => {
        setErrorMessage(null);
      }, 8000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }).format(value);
  };

  const handleSaleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    if (name === "document") {
      const onlyNumbers = value.replace(/\D/g, "");
      setDocument(onlyNumbers);
    }

    if (name === "orderNumber") setOrderNumber(value);
    if (name === "project") setProject(value);

    if (name === "projectValue") {
      const numericValue = value.replace(/\D/g, "");
      setRawProjectValue(numericValue);
      const floatValue = parseFloat(numericValue) / 100;
      if (!isNaN(floatValue)) {
        const formattedValue = formatCurrency(floatValue);
        setProjectValue(formattedValue);
      }
    }

    if (name === "store") setStore(value);
    if (name === "dateProject") setDateProject(value);
  };

  const handleClearInput = (field: string) => {
    if (field === "document") setDocument("");
    if (field === "orderNumber") setOrderNumber("");
    if (field === "project") setProject("");
    if (field === "projectValue") {
      setProjectValue("");
      setRawProjectValue("");
    }
    if (field === "store") setStore("");
    if (field === "dateProject") setDateProject("");
  };

  const handleSearchProfessional = async () => {
    setErrorMessage(null);
    setProfessionalData(null);

    if (!document) {
      setErrorMessage("Por favor, digite o CPF/CNPJ para buscar.");
      return;
    }

    try {
      const response = await searchProfessionals(document);
      if (response.results.length > 0) {
        setProfessionalData(response.results[0]);
      } else {
        setProfessionalData(null);
        toast.error("Nenhum profissional encontrado.");
      }
    } catch (error: any) {
      toast.error(error.message || "Erro ao buscar profissional.");
    }
  };

  const mapProfessionalType = (type: string) => {
    const types: { [key: string]: string } = {
      AR: "Arquiteto",
      EN: "Engenheiro",
      PA: "Paisagista",
      DI: "Designer de Interiores",
    };
    return types[type] || type;
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearchProfessional();
    }
  };

  const handleAddSale = async () => {
    if (
      !document ||
      !orderNumber ||
      !project ||
      !projectValue ||
      !store ||
      !dateProject
    ) {
      setErrorMessage("Por favor, preencha todos os campos.");
      return;
    }

    try {
      const saleData = {
        order_number: orderNumber,
        project: project,
        project_value: parseFloat(rawProjectValue) / 100,
        document: document,
        store: store,
        date_project: dateProject,
      };

      const response = await addSale(saleData);

      setOrderNumber("");
      setProject("");
      setProjectValue("");
      setDocument("");
      setStore("");
      setRawProjectValue("");
      setDateProject("");
      setErrorMessage(null);

      toast.success("Venda cadastrada com sucesso!");

      setProfessionalData(null);
      navigate(`/sales-list`);
      
    } catch (error: any) {

      console.error("Erro ao adicionar venda:", error);
      toast.error(error.message || "Erro ao adicionar a venda.");
    }
  };

  return (
    <div className="flex flex-col lg:flex-row lg:px-32 mx-auto p-6">
      {/* Formulário de Vendas */}
      <div className="order-2 lg:order-1 w-full lg:w-2/3 lg:pr-6 mb-6 lg:mb-0">
        <h3 className="text-4xl font-semibold mt-6">Cadastro de Compra</h3>

        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

        <div className="mt-6 relative">
          <label className="block mb-2">
            CPF/CNPJ do Profissional:
            <div className="relative">
              <MagnifyingGlassIcon
                className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                onClick={handleSearchProfessional}
              />
              <input
                type="text"
                name="document"
                value={document}
                onChange={handleSaleInputChange}
                onKeyDown={handleKeyPress}
                className="w-full p-2 pl-10 border border-gray-300 rounded mt-1"
                placeholder="Digite o CPF/CNPJ do profissional"
              />
              {document && (
                <XMarkIcon
                  className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => {
                    handleClearInput("document");
                    setProfessionalData(null);
                  }}
                />
              )}
            </div>
          </label>

          <div className="mb-4">
            <label className="block font-medium mb-1">Loja:</label>
            <select
              name="store"
              value={store}
              onChange={(e) => setStore(e.target.value)}
              required
              className="w-full p-2 border rounded"
            >
              <option value="" disabled>
                Selecione a loja
              </option>
              <option value="ST">Stokcasa</option>
              <option value="EU">Euromanaus</option>
              <option value="MS">MS Casa</option>
            </select>
          </div>

          <label className="block mb-2">
            Número do Pedido:
            <div className="relative">
              <input
                type="text"
                name="orderNumber"
                value={orderNumber}
                onChange={handleSaleInputChange}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                placeholder="Número do pedido"
              />
              {orderNumber && (
                <XMarkIcon
                  className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => handleClearInput("orderNumber")}
                />
              )}
            </div>
          </label>

          <label className="block mb-2">
            Nome do Projeto:
            <div className="relative">
              <input
                type="text"
                name="project"
                value={project}
                onChange={handleSaleInputChange}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                placeholder="Nome do projeto"
              />
              {project && (
                <XMarkIcon
                  className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => handleClearInput("project")}
                />
              )}
            </div>
          </label>

          <label className="block mb-2">
            Valor do Projeto:
            <div className="relative">
              <input
                type="text"
                name="projectValue"
                value={projectValue}
                onChange={handleSaleInputChange}
                className="w-full p-2 border border-gray-300 rounded mt-1"
                placeholder="Valor do projeto"
              />
              {projectValue && (
                <XMarkIcon
                  className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => handleClearInput("projectValue")}
                />
              )}
            </div>
          </label>

          <label className="block mb-4">
            Data do Projeto:
            <div className="relative">
              <input
                type="date"
                name="dateProject"
                value={dateProject}
                onChange={handleSaleInputChange}
                className="w-full p-2 border border-gray-300 rounded mt-1"
              />
              {dateProject && (
                <XMarkIcon
                  className="w-5 h-5 text-gray-400 absolute right-10 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => handleClearInput("dateProject")}
                />
              )}
            </div>
          </label>

          <button
            onClick={handleAddSale}
            className="btn-primary px-4 py-2 rounded mr-2"
          >
            Adicionar Compra
          </button>
        </div>
      </div>

      {/* Card de Exibição do Profissional */}
      <div className="order-1 lg:order-2 w-full lg:w-1/3 lg:pl-6">
        {professionalData && (
          <div className="bg-white border border-gray-200 shadow-lg rounded-lg p-6">
            <div className="flex flex-col items-center">
              <img
                src={professionalData.profile_picture}
                alt={professionalData.name}
                className="w-24 h-24 rounded-full object-cover mb-4"
              />
              <div className="text-center">
                <h2 className="text-lg font-bold">{professionalData.name}</h2>
                <p className="text-sm text-gray-500">
                  {mapProfessionalType(professionalData.professional_type)}
                </p>
                <div className="flex items-center justify-center mt-4">
                  <EnvelopeIcon className="w-5 h-5 text-gray-500 mr-2" />
                  <span>{professionalData.email}</span>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <PhoneIcon className="w-5 h-5 text-gray-500 mr-2" />
                  <span>{professionalData.phone}</span>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <HomeIcon className="w-5 h-5 text-gray-500 mr-2" />
                  <span>{professionalData.address}</span>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <IdentificationIcon className="w-5 h-5 text-gray-500 mr-2" />
                  <span>{professionalData.document}</span>
                </div>
                <div className="flex items-center justify-center mt-2">
                  <StarIcon className="w-5 h-5 text-gray-500 mr-2" />
                  <span>{professionalData.total_points}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sales;
