import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import LandingPage from "./components/landing-page";
import Login from "./components/login";
import PrivateRouteService from "./services/private-route-service";
import Registry from "./components/registry";
import SalesList from "./components/sale-list";
import ProfessionalPoints from "./components/professional-points";
import sales from "./components/sales";
import Profile from "./components/profile";
import Footer from "./components/footer";
import HandleLoggedInHeader from "./components/logged-in-header";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Layout = () => {
  const location = useLocation();

  const hideHeaderFooter = ["/", "/login", "/registry"].includes(location.pathname);

  return (
    <div className="app-container">
      {!hideHeaderFooter && (
        <header>
          <HandleLoggedInHeader />
        </header>
      )}
      <main className="content">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/registry" element={<Registry />} />
          <Route path="/login" element={<Login />} />
          <Route path="/professional-points/:id" element={<PrivateRouteService element={ProfessionalPoints} />} />
          <Route path="/profile/:id" element={<PrivateRouteService element={Profile} />} />
          <Route path="/sales-list" element={<PrivateRouteService element={SalesList} requiredStaff={true} />} />
          <Route path="/sales" element={<PrivateRouteService element={sales} requiredStaff={true} />} />
        </Routes>
      </main>
      {!hideHeaderFooter && (
        <footer>
          <Footer />
        </footer>
      )}
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <Layout />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Router>
  );
};

export default App;
