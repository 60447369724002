import React, { useRef, useState, useEffect } from "react";
import {
    ArrowUpTrayIcon,
    UserCircleIcon,
} from "@heroicons/react/24/solid";
import { validateImageSizeAndFormat } from "../../utils/imageValidator";
import { Dialog } from "@headlessui/react";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import type { ReactCropperElement } from 'react-cropper';

interface ImageUploaderProps {
    initialImage?: string;
    onImageChange: (file: File | null) => void;
    maxFileSizeMB?: number;
}

const ImageUploader: React.FC<ImageUploaderProps> = ({
    initialImage,
    onImageChange,
    maxFileSizeMB = 1,
}) => {
    const [imagePreview, setImagePreview] = useState<string | null>(initialImage || null);
    const [profilePicture, setProfilePicture] = useState<File | null>(null);
    const [imageErrorMessage, setImageErrorMessage] = useState<string | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const cropperRef = useRef<ReactCropperElement>(null);

    useEffect(() => {
        if (initialImage) {
            setImagePreview(initialImage);
        }
    }, [initialImage]);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            const error = validateImageSizeAndFormat(file, maxFileSizeMB);
            if (error) {
                setImageErrorMessage(error);
                setProfilePicture(null);
                setImagePreview(null);
                onImageChange(null);
            } else {
                setImageErrorMessage(null);
                setProfilePicture(file);

                const reader = new FileReader();
                reader.onloadend = () => {
                    setImagePreview(reader.result as string);
                    setIsModalOpen(true);
                };
                reader.readAsDataURL(file);
            }
        }
    };

    const handleClearImage = () => {
        setImagePreview(null);
        setProfilePicture(null);
        setImageErrorMessage(null);
        onImageChange(null);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        if (!imagePreview) {
            setProfilePicture(null);
        }
    };

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.getCroppedCanvas().toBlob((blob) => {
                if (blob) {
                    const croppedImageFile = new File([blob], 'cropped_image.jpg', {
                        type: 'image/jpeg',
                    });

                    const croppedImageUrl = URL.createObjectURL(blob);
                    setImagePreview(croppedImageUrl);
                    setProfilePicture(croppedImageFile);
                    onImageChange(croppedImageFile);
                    setIsModalOpen(false);
                }
            }, 'image/jpeg');
        }
    };

    return (
        <div className="border border-gray-300 p-4 rounded-lg">
            <div className="flex items-center mb-4">
                <UserCircleIcon className="size-8 text-gray-600" />
                <p className="ml-4 text-gray-600 font-medium">Edite sua foto</p>
            </div>

            <label className="block w-full bg-gray-100 border-2 border-dashed border-gray-400 rounded-lg p-6 text-center cursor-pointer">
                {!imagePreview ? (
                    <div className="flex flex-col items-center">
                        <ArrowUpTrayIcon className="size-6 text-gray-600" />
                        <span className="text-gray-600">Clique para fazer upload da foto</span>
                        <span className="text-gray-600">PNG, JPG</span>
                        <span className="text-gray-600">máx. 1mb</span>
                    </div>
                ) : (
                    <div className="flex flex-col items-center">
                        <img
                            src={imagePreview}
                            alt="Pré-visualização da Imagem"
                            className="w-48 h-48 object-cover rounded"
                        />
                        <span className="text-gray-600 mt-2">Clique para alterar a foto</span>
                    </div>
                )}
                <input type="file" onChange={handleFileChange} className="hidden" />
            </label>

            {imagePreview && (
                <div className="mt-4 text-center">
                    <button
                        type="button"
                        onClick={handleClearImage}
                        className="btn-delete bg-red-500 text-white px-4 py-2 rounded-lg"
                    >
                        Limpar
                    </button>
                </div>
            )}

            <Dialog
                open={isModalOpen}
                onClose={handleCancel}
                className="fixed z-10 inset-0 overflow-y-auto"
            >
                <div className="flex items-center justify-center min-h-screen bg-black bg-opacity-50 p-4">
                    <div className="relative bg-white rounded-lg shadow-xl max-w-[90vw] max-h-[90vh] flex flex-col">
                        <div className="p-4 border-b">
                            <Dialog.Title className="text-lg font-bold">
                                Recortar Imagem
                            </Dialog.Title>
                        </div>

                        <div className="flex-1 overflow-hidden" style={{ height: '60vh' }}>
                            {imagePreview && (
                                <Cropper
                                    src={imagePreview}
                                    style={{ height: '100%', width: '100%' }}
                                    aspectRatio={1}
                                    guides={true}
                                    ref={cropperRef}
                                    viewMode={1}
                                    dragMode="move"
                                    background={false}
                                    rotatable={false}
                                    zoomable={true}
                                    minCropBoxHeight={100}
                                    minCropBoxWidth={100}
                                    responsive={true}
                                    autoCropArea={0.5}
                                    checkOrientation={false}
                                />
                            )}
                        </div>

                        <div className="p-4 border-t flex gap-2 justify-end">
                            <button
                                onClick={handleCancel}
                                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleCrop}
                                className="btn-primary"
                            >
                                Recortar
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>

            {imageErrorMessage && (
                <div className="text-red-500 mt-2">{imageErrorMessage}</div>
            )}
        </div>
    );
};

export default ImageUploader;