export const validateDocument = (document: string): string | null => {
  const cleanedDocument = document.replace(/\D/g, '');

  if (cleanedDocument.length === 11) {
    return validateCPF(cleanedDocument);
  }

  if (cleanedDocument.length === 14) {
    return validateCNPJ(cleanedDocument);
  }

  return 'O documento deve conter 11 dígitos (CPF) ou 14 dígitos (CNPJ).';
};

export const validateCPF = (cpf: string): string | null => {
  const cleanedCPF = cpf.replace(/\D/g, '');
  if (cleanedCPF.length !== 11) {
    return 'O CPF deve conter 11 dígitos.';
  }

  const isAllDigitsSame = /^(\d)\1+$/.test(cleanedCPF);
  if (isAllDigitsSame) {
    return 'CPF inválido.';
  }

  const validateDigits = (cpfArray: number[], factor: number): number => {
    const total = cpfArray.reduce((acc, curr, index) => {
      return acc + curr * (factor - index);
    }, 0);
    const remainder = (total * 10) % 11;
    return remainder === 10 ? 0 : remainder;
  };

  const cpfArray = cleanedCPF.split('').map(Number);

  const firstVerifier = validateDigits(cpfArray.slice(0, 9), 10);
  if (firstVerifier !== cpfArray[9]) {
    return 'CPF inválido.';
  }

  const secondVerifier = validateDigits(cpfArray.slice(0, 10), 11);
  if (secondVerifier !== cpfArray[10]) {
    return 'CPF inválido.';
  }

  return null;
};

export const validateCNPJ = (cnpj: string): string | null => {
  const cleanedCNPJ = cnpj.replace(/\D/g, '');
  if (cleanedCNPJ.length !== 14) {
    return 'O CNPJ deve conter 14 dígitos.';
  }

  const isAllDigitsSame = /^(\d)\1+$/.test(cleanedCNPJ);
  if (isAllDigitsSame) {
    return 'CNPJ inválido.';
  }

  const validateCNPJDigits = (cnpjArray: number[], factors: number[]): number => {
    const total = cnpjArray.reduce((acc, curr, index) => {
      return acc + curr * factors[index];
    }, 0);
    const remainder = total % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const cnpjArray = cleanedCNPJ.split('').map(Number);

  const firstVerifier = validateCNPJDigits(cnpjArray.slice(0, 12), [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
  if (firstVerifier !== cnpjArray[12]) {
    return 'CNPJ inválido.';
  }

  const secondVerifier = validateCNPJDigits(cnpjArray.slice(0, 13), [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]);
  if (secondVerifier !== cnpjArray[13]) {
    return 'CNPJ inválido.';
  }

  return null;
};
